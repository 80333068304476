<template>
  <section>
    <div v-if="!isLoaded" class="h-screen vld-parent">
      <loading :active="!isLoaded" :is-full-page="false" />
    </div>

    <div class="max-w-6xl mx-auto mt-3 lg:mt-10 md:mt-5" v-if="isLoaded">
      <!-- Maps -->
      <div class="mt-10 w-full">
        <div class="flex items-center mt-10 font-bold text-22px text-oCharcoal">
          <div class="circle-css-icon bg-mTeal" />
          <span class="pl-3">{{
            $t('components.settingsManagement.general.headline.map')
          }}</span>
        </div>
        <div :key="`map_${counter}`" class=" mt-3 pt-5 border-t-2">
          <template v-for="(item, itemIndex) in mapSettings">
            <SmartAppInput
              :key="itemIndex"
              :rowId="`map_${itemIndex}`"
              :type="item.type"
              :label="item.label"
              :placeholder="item.placeholder"
              :description="item.description"
              :isEdit="item.isEdit"
              v-model="maps[`${item.variable}`]"
              @save="onSave('general', `${item.variable}`, $event)"
            />
          </template>
        </div>
      </div>
      <!-- chatbot id -->
      <div class="mt-10 w-full">
        <div class="flex items-center mt-10 font-bold text-22px text-oCharcoal">
          <div class="circle-css-icon bg-gray-700" />
          <span class="pl-3">
            {{ $t('components.settingsManagement.general.headline.chatbot') }}
          </span>
          <!-- <img class="ml-3 w-7 h-7" src="@/assets/img/new.png" /> -->
          <div
            class="ml-2 bg-oPurple px-2 py-1 rounded-lg text-xs text-oWhite "
          >
            <p>BETA</p>
          </div>
        </div>
        <div :key="`chatbot_${counter}`" class=" mt-3 pt-5 border-t-2">
          <template v-for="(item, itemIndex) in chatbotSettings">
            <SmartAppInput
              :key="itemIndex"
              :rowId="`chatbot_${itemIndex}`"
              :type="item.type"
              :label="item.label"
              :placeholder="item.placeholder"
              :description="item.description"
              :isEdit="item.isEdit"
              v-model="chatbot[`${item.variable}`]"
              @save="onSave('general', `${item.variable}`, $event)"
            />
          </template>
        </div>
      </div>
      <!-- Marketplace -->
      <div class="mt-10 w-full">
        <div class="flex items-center mt-10 font-bold text-22px text-oCharcoal">
          <div class="circle-css-icon bg-green-600" />
          <span class="pl-3">
            {{
              $t('components.settingsManagement.general.headline.marketplace')
            }}
          </span>
          <!-- <img class="ml-3 w-7 h-7" src="@/assets/img/new.png" /> -->
          <div
            class="ml-2 bg-oPurple px-2 py-1 rounded-lg text-xs text-oWhite "
          >
            <p>BETA</p>
          </div>
        </div>
        <div :key="`marketplace_${counter}`" class=" mt-3 pt-5 border-t-2">
          <template v-for="(item, itemIndex) in marketplaceSettings">
            <SmartAppInput
              :key="itemIndex"
              :rowId="`marketplace_${itemIndex}`"
              :type="item.type"
              :label="item.label"
              :placeholder="item.placeholder"
              :description="item.description"
              :isEdit="item.isEdit"
              v-model="marketplace[`${item.variable}`]"
              @save="onSave('org', `${item.variable}`, $event)"
            />
          </template>
        </div>
      </div>

      <!-- Maps -->
      <div class="mt-10 w-full">
        <div>
          <div class="flex items-center">
            <div class="circle-css-icon bg-orange-500" />
            <div class="pl-3 font-bold text-22px text-oCharcoal">
              {{
                $t(
                  'components.settingsManagement.general.headline.spaceInvoice.title'
                )
              }}
            </div>
          </div>
          <p class="break-words description-text ">
            {{
              $t(
                'components.settingsManagement.general.headline.spaceInvoice.description'
              )
            }}
          </p>
        </div>
        <div :key="`spaceInvoice_${counter}`" class=" mt-3 pt-5 border-t-2">
          <SmartAppInput
            :key="`spaceInvoice_${counter + 0}`"
            :rowId="`spaceInvoice_${0}`"
            :type="spaceInvoiceSettings[0].type"
            :label="spaceInvoiceSettings[0].label"
            :description="spaceInvoiceSettings[0].description"
            :isEdit="spaceInvoiceSettings[0].isEdit"
            :options="invoiceServiceChoices"
            v-model="spaceInvoices[`${spaceInvoiceSettings[0].variable}`]"
            @save="
              onSave('general', `${spaceInvoiceSettings[0].variable}`, $event)
            "
          />
          <SmartAppInput
            v-if="spaceInvoices.invoicing_service !== 0"
            :key="`spaceInvoice_${counter + 1}`"
            :rowId="`spaceInvoice_${1}`"
            :type="spaceInvoiceSettings[1].type"
            :label="spaceInvoiceSettings[1].label"
            :description="spaceInvoiceSettings[1].description"
            :isEdit="spaceInvoiceSettings[1].isEdit"
            v-model="spaceInvoices[`${spaceInvoiceSettings[1].variable}`]"
            @save="
              onSave('general', `${spaceInvoiceSettings[1].variable}`, $event)
            "
          />
          <SmartAppInput
            v-if="spaceInvoices.invoicing_service !== 0"
            :key="`spaceInvoice_${counter + 2}`"
            :rowId="`spaceInvoice_${2}`"
            :type="spaceInvoiceSettings[2].type"
            :label="spaceInvoiceSettings[2].label"
            :description="spaceInvoiceSettings[2].description"
            :isEdit="spaceInvoiceSettings[2].isEdit"
            v-model="spaceInvoices[`${spaceInvoiceSettings[2].variable}`]"
            @save="
              onSave('general', `${spaceInvoiceSettings[2].variable}`, $event)
            "
          />
          <SmartAppInput
            v-if="spaceInvoices.invoicing_service !== 0"
            :key="`spaceInvoice_${counter + 3}`"
            :rowId="`spaceInvoice_${3}`"
            :type="spaceInvoiceSettings[3].type"
            :label="spaceInvoiceSettings[3].label"
            :description="spaceInvoiceSettings[3].description"
            :isEdit="spaceInvoiceSettings[3].isEdit"
            v-model="spaceInvoices[`${spaceInvoiceSettings[3].variable}`]"
            @save="
              onSave('general', `${spaceInvoiceSettings[3].variable}`, $event)
            "
          />
          <SmartAppInput
            v-if="spaceInvoices.invoicing_service !== 0"
            :key="`spaceInvoice_${counter + 4}`"
            :rowId="`spaceInvoice_${4}`"
            :type="spaceInvoiceSettings[4].type"
            :label="spaceInvoiceSettings[4].label"
            :description="spaceInvoiceSettings[4].description"
            :isEdit="spaceInvoiceSettings[4].isEdit"
            v-model="spaceInvoices[`${spaceInvoiceSettings[4].variable}`]"
            @save="
              onSave('general', `${spaceInvoiceSettings[4].variable}`, $event)
            "
          />
        </div>
      </div>

      <!-- SMS Gateway -->
      <div class="mt-10 w-full">
        <div class="flex items-center mt-10 font-bold text-22px text-oCharcoal">
          <div class="circle-css-icon bg-mIndigo" />
          <span class="pl-3">
            {{
              $t(
                'components.settingsManagement.general.headline.chooseSmsGateway'
              )
            }}
          </span>
        </div>
        <div :key="`smsGateway_${counter}`" class=" mt-3 pt-5 border-t-2">
          <template v-for="(item, itemIndex) in smsGatewaySettings">
            <SmartAppInput
              :key="itemIndex"
              :rowId="`smsGateway_${itemIndex}`"
              :type="item.type"
              :label="item.label"
              :placeholder="item.placeholder"
              :options="item.options"
              :description="item.description"
              :isEdit="item.isEdit"
              v-model="smsGateway[`${item.variable}`]"
              @save="onSave('general', `${item.variable}`, $event)"
            />
          </template>
        </div>
      </div>

      <!-- twilio -->
      <div class="mt-10 w-full" v-if="smsGateway.sms_gateway === 'Twilio'">
        <div class="flex items-center mt-10 font-bold text-22px text-oCharcoal">
          <div class="circle-css-icon bg-mRed" />
          <span class="pl-3">
            {{
              $t(
                'components.settingsManagement.general.headline.twilioSmsGateway'
              )
            }}</span
          >
        </div>
        <div :key="`twilio_${counter}`" class=" mt-3 pt-5 border-t-2">
          <template v-for="(item, itemIndex) in twilioSettings">
            <SmartAppInput
              :key="itemIndex"
              :rowId="`twilio_${itemIndex}`"
              :type="item.type"
              :label="item.label"
              :placeholder="item.placeholder"
              :description="item.description"
              :isEdit="item.isEdit"
              v-model="twilio[`${item.variable}`]"
              @save="onSave('general', `${item.variable}`, $event)"
            />
          </template>
        </div>
      </div>

      <!-- Misr -->
      <div class="mt-10 w-full" v-if="smsGateway.sms_gateway === 'Misr'">
        <div class="flex items-center mt-10 font-bold text-22px text-oCharcoal">
          <div class="circle-css-icon bg-mEmerald" />
          <span class="pl-3">
            {{
              $t(
                'components.settingsManagement.general.headline.misrSmsGateway'
              )
            }}
          </span>
        </div>
        <div :key="`misr_${counter}`" class=" mt-3 pt-5 border-t-2">
          <template v-for="(item, itemIndex) in misrSettings">
            <SmartAppInput
              :key="itemIndex"
              :rowId="`misr_${itemIndex}`"
              :type="item.type"
              :label="item.label"
              :placeholder="item.placeholder"
              :description="item.description"
              :isEdit="item.isEdit"
              v-model="misr[`${item.variable}`]"
              @save="onSave('general', `${item.variable}`, $event)"
            />
          </template>
        </div>
      </div>

      <!-- SmsPro -->
      <div class="mt-10 w-full" v-if="smsGateway.sms_gateway === 'Smspro'">
        <div class="flex items-center mt-10 font-bold text-22px text-oCharcoal">
          <div class="circle-css-icon bg-yellow-800" />
          <span class="pl-3">
            {{
              $t(
                'components.settingsManagement.general.headline.smsproSmsGateway'
              )
            }}
          </span>
        </div>
        <div :key="`smspro_${counter}`" class=" mt-3 pt-5 border-t-2">
          <template v-for="(item, itemIndex) in smsProSettings">
            <SmartAppInput
              :key="itemIndex"
              :rowId="`smspro_${itemIndex}`"
              :type="item.type"
              :label="item.label"
              :placeholder="item.placeholder"
              :description="item.description"
              :isEdit="item.isEdit"
              v-model="smspro[`${item.variable}`]"
              @save="onSave('general', `${item.variable}`, $event)"
            />
          </template>
        </div>
      </div>

      <!-- Unifonic -->
      <div class="mt-10 w-full" v-if="smsGateway.sms_gateway === 'Unifonic'">
        <div class="flex items-center mt-10 font-bold text-22px text-oCharcoal">
          <div class="circle-css-icon bg-red-300" />
          <span class="pl-3">
            {{
              $t(
                'components.settingsManagement.general.headline.unifonicSmsGateway'
              )
            }}
          </span>
        </div>
        <div :key="`unifonic_${counter}`" class=" mt-3 pt-5 border-t-2">
          <template v-for="(item, itemIndex) in unifonicSettings">
            <SmartAppInput
              :key="itemIndex"
              :rowId="`unifonic_${itemIndex}`"
              :type="item.type"
              :label="item.label"
              :placeholder="item.placeholder"
              :description="item.description"
              :isEdit="item.isEdit"
              v-model="unifonic[`${item.variable}`]"
              @save="onSave('general', `${item.variable}`, $event)"
            />
          </template>
        </div>
      </div>

      <!-- unimatrix -->
      <div class="mt-10 w-full" v-if="smsGateway.sms_gateway === 'Unimatrix'">
        <div class="flex items-center mt-10 font-bold text-22px text-oCharcoal">
          <div class="circle-css-icon bg-mRed" />
          <span class="pl-3">
            {{
              $t(
                'components.settingsManagement.general.headline.unimatrixSmsGateway'
              )
            }}</span
          >
        </div>
        <div :key="`unimatrix_${counter}`" class=" mt-3 pt-5 border-t-2">
          <template v-for="(item, itemIndex) in unimatrixSettings">
            <SmartAppInput
              :key="itemIndex"
              :rowId="`unimatrix_${itemIndex}`"
              :type="item.type"
              :label="item.label"
              :placeholder="item.placeholder"
              :description="item.description"
              :isEdit="item.isEdit"
              v-model="unimatrix[`${item.variable}`]"
              @save="onSave('general', `${item.variable}`, $event)"
            />
          </template>
        </div>
      </div>

      <!-- Web Links -->
      <div class="mt-10 w-full">
        <div class="flex items-center mt-10 font-bold text-22px text-oCharcoal">
          <div class="circle-css-icon bg-yellow-800" />
          <span class="pl-3">
            {{ $t('components.settingsManagement.general.headline.webLinks') }}
          </span>
        </div>
        <div :key="`webLinks_${counter}`" class=" mt-3 pt-5 border-t-2">
          <template v-for="(item, itemIndex) in webLinksSettings">
            <SmartAppInput
              :key="itemIndex"
              :rowId="`webLinks_${itemIndex}`"
              :type="item.type"
              :label="item.label"
              :placeholder="item.placeholder"
              :description="item.description"
              :isEdit="item.isEdit"
              v-model="webLinks[`${item.variable}`]"
              @save="onSave('org', `${item.variable}`, $event)"
            />
          </template>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import SmartAppInput from '@/components/form/SmartAppInput.vue'
import { EventBus } from '@/utils/EventBus'
import { useEndpoints } from '@/composables'
export default {
  name: 'BasicSettings',
  components: {
    SmartAppInput,
  },
  data() {
    return {
      isLoaded: false,
      invoiceServiceChoices: [],
      counter: 0,
      currentSettingsGroup: null,
      referral: {
        invitation_amount: '',
        invitation_required_trips: '',
      },
      maps: {
        gmap_api_key: '',
        mapbox_api_key: '',
      },
      chatbot: {
        chatbot_id: '',
        magnusai_api_key: '',
        magnusai_document_upload_api: '',
      },
      marketplace: {
        marketplace_base_url: '',
      },
      smsGateway: {
        sms_gateway: '',
      },
      twilio: {
        twilio_sid: '',
        twilio_auth_token: '',
        twilio_from_phone_number: '',
      },
      misr: {
        misr_api_username: '',
        misr_api_password: '',
        misr_template: '',
        misr_sender: '',
      },
      smspro: {
        smspro_login_name: '',
        smspro_login_password: '',
        smspro_from: '',
      },
      unifonic: {
        unifonic_appsid: '',
        unifonic_sender_id: '',
        unifonic_base_url: '',
      },
      unimatrix: {
        unimatrix_access_key_id: '',
      },

      webLinks: {
        play_store_link: '',
        apple_store_link: '',
        website_link: '',
        email_address: '',
      },
      inPlaceEditingState: {
        referral_invitation_amount: false,
        referral_invitation_required_trips: false,
        maps_gmap_api_key: false,
        maps_mapbox_api_key: false,
        twilio_twilio_sid: false,
        twilio_twilio_auth_token: false,
        twilio_twilio_from_phone_number: false,
      },
      spaceInvoices: {
        invoicing_service: 0,
        space_invoices_access_token: '',
        space_invoices_organization_id: '',
        space_invoices_business_premise_id: '',
        space_invoices_electronic_device_id: '',
      },
      mapSettings: [
        {
          type: 'text',
          label: this.$t(
            'components.settingsManagement.general.map.googleMapApiKey.title'
          ),
          placeholder: this.$t(
            'components.settingsManagement.general.map.googleMapApiKey.placeHolder'
          ),
          description: this.$t(
            'components.settingsManagement.general.map.googleMapApiKey.description'
          ),
          variable: 'gmap_api_key',
          isEdit: false,
        },
        {
          type: 'text',
          label: this.$t(
            'components.settingsManagement.general.map.mapBoxApiKey.title'
          ),
          placeholder: this.$t(
            'components.settingsManagement.general.map.mapBoxApiKey.placeHolder'
          ),
          description: this.$t(
            'components.settingsManagement.general.map.mapBoxApiKey.description'
          ),
          variable: 'mapbox_api_key',
          isEdit: false,
        },
      ],
      chatbotSettings: [
        {
          type: 'text',
          label: this.$t(
            'components.settingsManagement.general.chatbot.chatbotId.title'
          ),
          placeholder: this.$t(
            'components.settingsManagement.general.chatbot.chatbotId.placeHolder'
          ),
          description: this.$t(
            'components.settingsManagement.general.chatbot.chatbotId.description'
          ),
          variable: 'chatbot_id',
          isEdit: false,
        },
        {
          type: 'text',
          label: this.$t(
            'components.settingsManagement.general.chatbot.provideMagnusaiApiKey.title'
          ),
          placeholder: this.$t(
            'components.settingsManagement.general.chatbot.provideMagnusaiApiKey.placeHolder'
          ),
          description: this.$t(
            'components.settingsManagement.general.chatbot.provideMagnusaiApiKey.description'
          ),
          variable: 'magnusai_api_key',
          isEdit: false,
        },
        {
          type: 'text',
          label: this.$t(
            'components.settingsManagement.general.chatbot.provideMagnusAIDocumentAPI.title'
          ),
          placeholder: this.$t(
            'components.settingsManagement.general.chatbot.provideMagnusAIDocumentAPI.placeHolder'
          ),
          description: this.$t(
            'components.settingsManagement.general.chatbot.provideMagnusAIDocumentAPI.description'
          ),
          variable: 'magnusai_document_upload_api',
          isEdit: false,
        },
      ],
      marketplaceSettings: [
        {
          type: 'text',
          label: this.$t(
            'components.settingsManagement.general.marketplace.marketplaceWebsite.title'
          ),
          placeholder: this.$t(
            'components.settingsManagement.general.marketplace.marketplaceWebsite.placeHolder'
          ),
          description: this.$t(
            'components.settingsManagement.general.marketplace.marketplaceWebsite.description'
          ),
          variable: 'marketplace_base_url',
          isEdit: false,
        },
      ],
      spaceInvoiceSettings: [
        {
          type: 'richselect',
          label: this.$t(
            'components.settingsManagement.general.spaceInvoice.chooseInvoiceService.title'
          ),
          description: this.$t(
            'components.settingsManagement.general.spaceInvoice.chooseInvoiceService.description'
          ),
          variable: 'invoicing_service',
          isEdit: false,
        },
        {
          type: 'text',
          label: this.$t(
            'components.settingsManagement.general.spaceInvoice.accessToken.title'
          ),
          description: this.$t(
            'components.settingsManagement.general.spaceInvoice.accessToken.description'
          ),
          placeholder: 'e.g. 343s734343s',
          variable: 'space_invoices_access_token',
          isEdit: false,
        },
        {
          type: 'text',
          label: this.$t(
            'components.settingsManagement.general.spaceInvoice.orgId.title'
          ),
          placeholder: 'e.g. 343s734343s',
          description: this.$t(
            'components.settingsManagement.general.spaceInvoice.orgId.description'
          ),
          variable: 'space_invoices_organization_id',
          isEdit: false,
        },
        {
          type: 'text',
          label: this.$t(
            'components.settingsManagement.general.spaceInvoice.spaceInvoicesBusinessPremiseId.title'
          ),
          placeholder: 'e.g. 343s734343s',
          description: this.$t(
            'components.settingsManagement.general.spaceInvoice.spaceInvoicesBusinessPremiseId.description'
          ),
          variable: 'space_invoices_business_premise_id',
          isEdit: false,
        },
        {
          type: 'text',
          label: this.$t(
            'components.settingsManagement.general.spaceInvoice.spaceInvoicesElectronicDeviceId.title'
          ),
          placeholder: 'e.g. 343s734343s',
          description: this.$t(
            'components.settingsManagement.general.spaceInvoice.spaceInvoicesElectronicDeviceId.description'
          ),
          variable: 'space_invoices_electronic_device_id',
          isEdit: false,
        },
      ],
      smsGatewaySettings: [
        {
          type: 'richselect',
          label: this.$t(
            'components.settingsManagement.general.chooseSmsGateway.title'
          ),
          placeholder: this.$t(
            'components.settingsManagement.general.chooseSmsGateway.placeHolder'
          ),
          options: [],
          description: this.$t(
            'components.settingsManagement.general.chooseSmsGateway.description'
          ),
          variable: 'sms_gateway',
          isEdit: false,
        },
      ],
      twilioSettings: [
        {
          type: 'text',
          label: this.$t(
            'components.settingsManagement.general.twilioSmsGateway.accountSID.title'
          ),
          placeholder: this.$t(
            'components.settingsManagement.general.twilioSmsGateway.accountSID.placeHolder'
          ),
          description: this.$t(
            'components.settingsManagement.general.twilioSmsGateway.accountSID.description'
          ),
          variable: 'twilio_sid',
          isEdit: false,
        },
        {
          type: 'text',
          label: this.$t(
            'components.settingsManagement.general.twilioSmsGateway.authToken.title'
          ),
          placeholder: this.$t(
            'components.settingsManagement.general.twilioSmsGateway.authToken.placeHolder'
          ),
          description: this.$t(
            'components.settingsManagement.general.twilioSmsGateway.authToken.description'
          ),

          variable: 'twilio_auth_token',
          isEdit: false,
        },
        {
          type: 'text',

          label: this.$t(
            'components.settingsManagement.general.twilioSmsGateway.fromPhoneNumber.title'
          ),
          placeholder: this.$t(
            'components.settingsManagement.general.twilioSmsGateway.fromPhoneNumber.placeHolder'
          ),
          description: this.$t(
            'components.settingsManagement.general.twilioSmsGateway.fromPhoneNumber.description'
          ),
          variable: 'twilio_from_phone_number',
          isEdit: false,
        },
      ],
      misrSettings: [
        {
          type: 'text',
          label: this.$t(
            'components.settingsManagement.general.misrSmsGateway.apiUsername.title'
          ),
          placeholder: this.$t(
            'components.settingsManagement.general.misrSmsGateway.apiUsername.placeHolder'
          ),
          description: this.$t(
            'components.settingsManagement.general.misrSmsGateway.apiUsername.description'
          ),

          variable: 'misr_api_username',
          isEdit: false,
        },
        {
          type: 'text',
          label: this.$t(
            'components.settingsManagement.general.misrSmsGateway.apiPassword.title'
          ),
          placeholder: this.$t(
            'components.settingsManagement.general.misrSmsGateway.apiPassword.placeHolder'
          ),
          description: this.$t(
            'components.settingsManagement.general.misrSmsGateway.apiPassword.description'
          ),
          variable: 'misr_api_password',
          isEdit: false,
        },
        {
          type: 'text',
          label: this.$t(
            'components.settingsManagement.general.misrSmsGateway.mTemplate.title'
          ),
          placeholder: this.$t(
            'components.settingsManagement.general.misrSmsGateway.mTemplate.placeHolder'
          ),
          description: this.$t(
            'components.settingsManagement.general.misrSmsGateway.mTemplate.description'
          ),
          variable: 'misr_template',
          isEdit: false,
        },
        {
          type: 'text',
          label: this.$t(
            'components.settingsManagement.general.misrSmsGateway.mSender.title'
          ),
          placeholder: this.$t(
            'components.settingsManagement.general.misrSmsGateway.mSender.placeHolder'
          ),
          description: this.$t(
            'components.settingsManagement.general.misrSmsGateway.mSender.description'
          ),
          variable: 'misr_sender',
          isEdit: false,
        },
      ],
      smsProSettings: [
        {
          type: 'text',
          label: this.$t(
            'components.settingsManagement.general.smsProGateway.loginName.title'
          ),
          placeholder: '',
          description: this.$t(
            'components.settingsManagement.general.smsProGateway.loginName.description'
          ),

          variable: 'smspro_login_name',
          isEdit: false,
        },
        {
          type: 'text',
          label: this.$t(
            'components.settingsManagement.general.smsProGateway.loginPassword.title'
          ),
          placeholder: '',
          description: this.$t(
            'components.settingsManagement.general.smsProGateway.loginPassword.description'
          ),
          variable: 'smspro_login_password',
          isEdit: false,
        },
        {
          type: 'text',
          label: this.$t(
            'components.settingsManagement.general.smsProGateway.from.title'
          ),
          placeholder: '',
          description: this.$t(
            'components.settingsManagement.general.smsProGateway.from.description'
          ),
          variable: 'smspro_from',
          isEdit: false,
        },
      ],
      unifonicSettings: [
        {
          type: 'text',
          label: this.$t(
            'components.settingsManagement.general.unifonicSmsGateway.appsId.title'
          ),
          placeholder: '',
          description: this.$t(
            'components.settingsManagement.general.unifonicSmsGateway.appsId.description'
          ),

          variable: 'unifonic_appsid',
          isEdit: false,
        },
        {
          type: 'text',
          label: this.$t(
            'components.settingsManagement.general.unifonicSmsGateway.senderId.title'
          ),
          placeholder: '',
          description: this.$t(
            'components.settingsManagement.general.unifonicSmsGateway.senderId.description'
          ),
          variable: 'unifonic_sender_id',
          isEdit: false,
        },
        {
          type: 'text',
          label: this.$t(
            'components.settingsManagement.general.unifonicSmsGateway.baseUrl.title'
          ),
          placeholder: '',
          description: this.$t(
            'components.settingsManagement.general.unifonicSmsGateway.baseUrl.description'
          ),
          variable: 'unifonic_base_url',
          isEdit: false,
        },
      ],
      unimatrixSettings: [
        {
          type: 'text',
          label: this.$t(
            'components.settingsManagement.general.unimatrixSmsGateway.unimatrixAccessKeyId.title'
          ),
          placeholder: this.$t(
            'components.settingsManagement.general.unimatrixSmsGateway.unimatrixAccessKeyId.placeHolder'
          ),
          description: this.$t(
            'components.settingsManagement.general.unimatrixSmsGateway.unimatrixAccessKeyId.description'
          ),
          variable: 'unimatrix_access_key_id',
          isEdit: false,
        },
      ],
      webLinksSettings: [
        {
          type: 'text',
          label: this.$t(
            'components.settingsManagement.general.webLinks.playStore.title'
          ),
          placeholder: '',
          description: this.$t(
            'components.settingsManagement.general.webLinks.playStore.description'
          ),

          variable: 'play_store_link',
          isEdit: false,
        },
        {
          type: 'text',
          label: this.$t(
            'components.settingsManagement.general.webLinks.appleStore.title'
          ),
          placeholder: '',
          description: this.$t(
            'components.settingsManagement.general.webLinks.appleStore.description'
          ),

          variable: 'apple_store_link',
          isEdit: false,
        },
        {
          type: 'text',
          label: this.$t(
            'components.settingsManagement.general.webLinks.website.title'
          ),
          placeholder: '',
          description: this.$t(
            'components.settingsManagement.general.webLinks.website.description'
          ),

          variable: 'website_link',
          isEdit: false,
        },
        {
          type: 'text',
          label: this.$t(
            'components.settingsManagement.general.webLinks.email.title'
          ),
          placeholder: '',
          description: this.$t(
            'components.settingsManagement.general.webLinks.email.description'
          ),

          variable: 'email_address',
          isEdit: false,
        },
      ],
    }
  },

  computed: {
    orgId() {
      return this.$store.getters['auth/organizationInfo'].id || null
    },
  },
  watch: {
    currentSettingsGroup: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val === 'map') {
          this.smsGatewaySettings = this.smsGatewaySettings.map((item) => ({
            ...item,
            isEdit: false,
          }))
          this.marketplaceSettings = this.marketplaceSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))

          this.twilioSettings = this.twilioSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))

          this.misrSettings = this.misrSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))
          this.smsProSettings = this.smsProSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))
          this.spaceInvoiceSettings = this.spaceInvoiceSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))
          this.chatbotSettings = this.chatbotSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))
          this.unifonicSettings = this.unifonicSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))
          this.webLinksSettings = this.webLinksSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))
        }
        if (val === 'smsGateway') {
          this.mapSettings = this.mapSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))
          this.marketplaceSettings = this.marketplaceSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))

          this.twilioSettings = this.twilioSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))

          this.misrSettings = this.misrSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))
          this.smsProSettings = this.smsProSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))
          this.spaceInvoiceSettings = this.spaceInvoiceSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))
          this.chatbotSettings = this.chatbotSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))
          this.unifonicSettings = this.unifonicSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))
          this.webLinksSettings = this.webLinksSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))
        }
        if (val === 'twilio') {
          this.mapSettings = this.mapSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))
          this.marketplaceSettings = this.marketplaceSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))

          this.smsGatewaySettings = this.smsGatewaySettings.map((item) => ({
            ...item,
            isEdit: false,
          }))

          this.misrSettings = this.misrSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))
          this.smsProSettings = this.smsProSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))
          this.spaceInvoiceSettings = this.spaceInvoiceSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))
          this.chatbotSettings = this.chatbotSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))
          this.unifonicSettings = this.unifonicSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))
          this.webLinksSettings = this.webLinksSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))
        }
        if (val === 'misr') {
          this.mapSettings = this.mapSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))

          this.marketplaceSettings = this.marketplaceSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))

          this.smsGatewaySettings = this.smsGatewaySettings.map((item) => ({
            ...item,
            isEdit: false,
          }))

          this.twilioSettings = this.twilioSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))
          this.smsProSettings = this.smsProSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))
          this.spaceInvoiceSettings = this.spaceInvoiceSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))
          this.chatbotSettings = this.chatbotSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))
          this.unifonicSettings = this.unifonicSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))
          this.webLinksSettings = this.webLinksSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))
        }

        if (val === 'smspro') {
          this.mapSettings = this.mapSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))
          this.marketplaceSettings = this.marketplaceSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))

          this.smsGatewaySettings = this.smsGatewaySettings.map((item) => ({
            ...item,
            isEdit: false,
          }))

          this.twilioSettings = this.twilioSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))

          this.misrSettings = this.misrSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))
          this.spaceInvoiceSettings = this.spaceInvoiceSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))
          this.chatbotSettings = this.chatbotSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))
          this.unifonicSettings = this.unifonicSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))
          this.webLinksSettings = this.webLinksSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))
        }

        if (val === 'unifonic') {
          this.mapSettings = this.mapSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))

          this.marketplaceSettings = this.marketplaceSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))

          this.smsGatewaySettings = this.smsGatewaySettings.map((item) => ({
            ...item,
            isEdit: false,
          }))
          this.smsProSettings = this.smsProSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))
          this.twilioSettings = this.twilioSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))

          this.misrSettings = this.misrSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))
          this.spaceInvoiceSettings = this.spaceInvoiceSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))
          this.chatbotSettings = this.chatbotSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))
          this.webLinksSettings = this.webLinksSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))
        }

        if (val === 'spaceInvoice') {
          this.mapSettings = this.mapSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))

          this.marketplaceSettings = this.marketplaceSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))
          this.smsGatewaySettings = this.smsGatewaySettings.map((item) => ({
            ...item,
            isEdit: false,
          }))

          this.twilioSettings = this.twilioSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))

          this.misrSettings = this.misrSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))
          this.smsProSettings = this.smsProSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))
          this.chatbotSettings = this.chatbotSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))
          this.unifonicSettings = this.unifonicSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))
          this.webLinksSettings = this.webLinksSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))
        }

        if (val === 'chatbot') {
          this.mapSettings = this.mapSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))

          this.marketplaceSettings = this.marketplaceSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))

          this.smsGatewaySettings = this.smsGatewaySettings.map((item) => ({
            ...item,
            isEdit: false,
          }))

          this.twilioSettings = this.twilioSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))

          this.misrSettings = this.misrSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))
          this.smsProSettings = this.smsProSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))
          this.unifonicSettings = this.unifonicSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))
          this.webLinksSettings = this.webLinksSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))
          this.spaceInvoiceSettings = this.spaceInvoiceSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))
        }
        if (val === 'marketplace') {
          this.mapSettings = this.mapSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))

          this.chatbotSettings = this.chatbotSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))

          this.smsGatewaySettings = this.smsGatewaySettings.map((item) => ({
            ...item,
            isEdit: false,
          }))

          this.twilioSettings = this.twilioSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))

          this.misrSettings = this.misrSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))
          this.smsProSettings = this.smsProSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))
          this.unifonicSettings = this.unifonicSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))
          this.webLinksSettings = this.webLinksSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))
          this.spaceInvoiceSettings = this.spaceInvoiceSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))
        }

        if (val === 'webLinks') {
          this.mapSettings = this.mapSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))

          this.marketplaceSettings = this.marketplaceSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))

          this.smsGatewaySettings = this.smsGatewaySettings.map((item) => ({
            ...item,
            isEdit: false,
          }))

          this.twilioSettings = this.twilioSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))

          this.misrSettings = this.misrSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))
          this.smsProSettings = this.smsProSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))
          this.unifonicSettings = this.unifonicSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))

          this.chatbotSettings = this.chatbotSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))
          this.spaceInvoiceSettings = this.spaceInvoiceSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))
        }
      },
    },
  },
  mounted() {
    EventBus.$on('open-edit', (id) => {
      const idPrefix = id.split('_')[0]
      console.log('idPrefix', idPrefix)
      if (idPrefix === 'map') {
        this.mapSettings = this.mapSettings.map((item, itemIndex) => {
          if (id === `map_${itemIndex}`) {
            return {
              ...item,
              isEdit: true,
            }
          } else {
            return {
              ...item,
              isEdit: false,
            }
          }
        })
        this.currentSettingsGroup = 'map'
      }
      if (idPrefix === 'chatbot') {
        this.chatbotSettings = this.chatbotSettings.map((item, itemIndex) => {
          if (id === `chatbot_${itemIndex}`) {
            return {
              ...item,
              isEdit: true,
            }
          } else {
            return {
              ...item,
              isEdit: false,
            }
          }
        })
        this.currentSettingsGroup = 'chatbot'
      }
      if (idPrefix === 'marketplace') {
        this.marketplaceSettings = this.marketplaceSettings.map(
          (item, itemIndex) => {
            if (id === `marketplace_${itemIndex}`) {
              return {
                ...item,
                isEdit: true,
              }
            } else {
              return {
                ...item,
                isEdit: false,
              }
            }
          }
        )
        this.currentSettingsGroup = 'marketplace'
      }
      if (idPrefix === 'spaceInvoice') {
        this.spaceInvoiceSettings = this.spaceInvoiceSettings.map(
          (item, itemIndex) => {
            if (id === `spaceInvoice_${itemIndex}`) {
              return {
                ...item,
                isEdit: true,
              }
            } else {
              return {
                ...item,
                isEdit: false,
              }
            }
          }
        )
        this.currentSettingsGroup = 'spaceInvoice'
      }
      if (idPrefix === 'smsGateway') {
        this.smsGatewaySettings = this.smsGatewaySettings.map(
          (item, itemIndex) => {
            if (id === `smsGateway_${itemIndex}`) {
              return {
                ...item,
                isEdit: true,
              }
            } else {
              return {
                ...item,
                isEdit: false,
              }
            }
          }
        )
        this.currentSettingsGroup = 'smsGateway'
      }
      if (idPrefix === 'twilio') {
        this.twilioSettings = this.twilioSettings.map((item, itemIndex) => {
          if (id === `twilio_${itemIndex}`) {
            return {
              ...item,
              isEdit: true,
            }
          } else {
            return {
              ...item,
              isEdit: false,
            }
          }
        })
        this.currentSettingsGroup = 'twilio'
      }
      if (idPrefix === 'misr') {
        this.misrSettings = this.misrSettings.map((item, itemIndex) => {
          if (id === `misr_${itemIndex}`) {
            return {
              ...item,
              isEdit: true,
            }
          } else {
            return {
              ...item,
              isEdit: false,
            }
          }
        })
        this.currentSettingsGroup = 'misr'
      }
      if (idPrefix === 'smspro') {
        this.smsProSettings = this.smsProSettings.map((item, itemIndex) => {
          if (id === `smspro_${itemIndex}`) {
            return {
              ...item,
              isEdit: true,
            }
          } else {
            return {
              ...item,
              isEdit: false,
            }
          }
        })
        this.currentSettingsGroup = 'smspro'
      }

      if (idPrefix === 'unifonic') {
        this.unifonicSettings = this.unifonicSettings.map((item, itemIndex) => {
          if (id === `unifonic_${itemIndex}`) {
            return {
              ...item,
              isEdit: true,
            }
          } else {
            return {
              ...item,
              isEdit: false,
            }
          }
        })
        this.currentSettingsGroup = 'unifonic'
      }
      if (idPrefix === 'webLinks') {
        this.webLinksSettings = this.webLinksSettings.map((item, itemIndex) => {
          if (id === `webLinks_${itemIndex}`) {
            return {
              ...item,
              isEdit: true,
            }
          } else {
            return {
              ...item,
              isEdit: false,
            }
          }
        })
        this.currentSettingsGroup = 'webLinks'
      }
    })
  },
  async created() {
    const generalSettingsReq = this.$http.get(
      useEndpoints.settings.general.index(this.orgId)
    )

    const invoiceServiceChoicesReq = this.$http.get(
      useEndpoints.settings.general.invoiceServiceChoices()
    )

    const orgDetailsReq = this.$http.get(
      useEndpoints.organization.details(this.orgId)
    )
    const requests = [
      generalSettingsReq,
      invoiceServiceChoicesReq,
      orgDetailsReq,
    ]

    this.$http.all(requests).then(
      this.$http.spread((...responses) => {
        this.isLoaded = true
        console.log('responses-g', responses)

        let data = responses[0].data
        this.referral.invitation_amount = data.invitation_amount
        this.referral.invitation_required_trips = data.invitation_required_trips

        this.maps.gmap_api_key = data.gmap_api_key
        this.maps.mapbox_api_key = data.mapbox_api_key

        this.chatbot.chatbot_id = data.chatbot_id
        this.chatbot.magnusai_api_key = data.magnusai_api_key
        this.chatbot.magnusai_document_upload_api =
          data.magnusai_document_upload_api

        this.smsGateway.sms_gateway = data.sms_gateway
        this.smsGatewaySettings[0].options = data.sms_gateway_choices

        this.twilio.twilio_sid = data.twilio_sid
        this.twilio.twilio_auth_token = data.twilio_auth_token
        this.twilio.twilio_from_phone_number = data.twilio_from_phone_number

        this.misr.misr_api_username = data.misr_api_username
        this.misr.misr_api_password = data.misr_api_password
        this.misr.misr_template = data.misr_template
        this.misr.misr_sender = data.misr_sender

        this.smspro.smspro_login_name = data.smspro_login_name
        this.smspro.smspro_login_password = data.smspro_login_password
        this.smspro.smspro_from = data.smspro_from

        this.unifonic.unifonic_appsid = data.unifonic_appsid
        this.unifonic.unifonic_sender_id = data.unifonic_sender_id
        this.unifonic.unifonic_base_url = data.unifonic_base_url

        this.smspro.invoicing_service = data.invoicing_service
        this.smspro.space_invoices_access_token =
          data.space_invoices_access_token
        this.smspro.space_invoices_organization_id =
          data.space_invoices_organization_id

        this.unimatrix.unimatrix_access_key_id = data.unimatrix_access_key_id

        this.spaceInvoices.invoicing_service = data.invoicing_service
        this.spaceInvoices.space_invoices_access_token =
          data.space_invoices_access_token
        this.spaceInvoices.space_invoices_organization_id =
          data.space_invoices_organization_id

        this.spaceInvoices.space_invoices_business_premise_id =
          data.space_invoices_business_premise_id

        this.spaceInvoices.space_invoices_electronic_device_id =
          data.space_invoices_electronic_device_id

        this.invoiceServiceChoices = responses[1].data

        this.webLinks.play_store_link = responses[2].data.play_store_link
        this.webLinks.apple_store_link = responses[2].data.apple_store_link
        this.webLinks.website_link = responses[2].data.website_link
        this.webLinks.email_address = responses[2].data.email_address

        this.marketplace.marketplace_base_url =
          responses[2].data.marketplace_base_url
      })
    )
  },
  methods: {
    async onSave(apiGroup, key, val) {
      this.counter++

      let url = ''
      if (apiGroup === 'general') {
        url = useEndpoints.settings.general.update(this.orgId)
      }
      if (apiGroup === 'org') {
        url = useEndpoints.organization.details(this.orgId)
      }
      let toastTitle, toastText

      toastTitle = `Successful`
      toastText = `Update successfully`

      let data = new FormData()
      data.append(key, val)

      await this.$http
        .patch(url, data)
        .then((res) => {
          console.log('general-res', res)

          this.mapSettings = this.mapSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))

          this.smsGatewaySettings = this.smsGatewaySettings.map((item) => ({
            ...item,
            isEdit: false,
          }))

          this.twilioSettings = this.twilioSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))

          this.misrSettings = this.misrSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))

          this.smsProSettings = this.smsProSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))
          this.webLinksSettings = this.webLinksSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))
          this.spaceInvoiceSettings = this.spaceInvoiceSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))
          this.chatbotSettings = this.chatbotSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))
          this.unifonicSettings = this.unifonicSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))

          this.marketplaceSettings = this.marketplaceSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))
          this.$notify(
            {
              group: 'generic',
              type: 'success',
              title: toastTitle,
              text: toastText,
            },
            3000
          )
          return true
        })
        .catch((err) => {
          console.log('general-err', { err })
          this.$notify(
            {
              group: 'generic',
              type: 'danger',
              title: 'Failed',
              text: 'Failed to update settings',
            },
            3000
          )
        })
    },
  },
}
</script>

<style lang="scss">
.circle-css-icon {
  width: 22px;
  height: 22px;
  border-radius: 50%;
}
.bg-mTeal {
  background-color: #32dc99;
}
.bg-mRed {
  background-color: #ff0d0d;
}
.bg-mIndigo {
  background-color: #4338ca;
}
.bg-mEmerald {
  background-color: #047857;
}

.description-text {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  margin-top: 2px;
  margin-bottom: 1px;
}
</style>
